<template>
    <div class="tgcss-max-w-[1200px] tgcss-m-auto tgcss-w-full tgcss-relative">


        <div class="card tgcss-w-full">
            <div class="card-body tgcss-w-full">
                <div class="tgcss-flex-grow tgcss-mb-5">
                    <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow">حساب‌های بانکی</div>
                </div>
                <div class="tgcss-grid tgcss-grid-cols-1 sm:tgcss-grid-cols-2 lg:tgcss-grid-cols-3 tgcss-gap-4">

                    <div v-for="(item, index) in bankCards" v-bind:key="index" @click="cardSelect = index" class="tgcss-w-full tgcss-cursor-pointer tgcss-flex tgcss-flex-col tgcss-h-56 tgcss-border tgcss-border-gray-200  tgcss-rounded-md tgcss-text-left">
                        <div class="tgcss-flex tgcss-items-center tgcss-p-5 tgcss-pb-2" dir="ltr">
                            <div class="tgcss-flex-grow"><img class="tgcss-w-14" :src="item.logo"></div>
                            <div v-if="cardSelect == index" class="tgcss-flex tgcss-text-xs tgcss-font-semibold tgcss-items-center tgcss-bg-gray-200 tgcss-rounded-full tgcss-px-3 tgcss-pb-1.5 tgcss-pt-1 tgcss-text-gray-700" dir="rtl">
                                <svg class="tgcss-w-4 tgcss-ml-1" data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m4.5 12.75 6 6 9-13.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                پیشفرض
                            </div>
                        </div>
                        <div class="tgcss-flex-grow tgcss-text-left tgcss-px-5">
                            <div v-if="index == 0" class="tgcss-w-auto tgcss-text-xs tgcss-font-semibold tgcss-inline-block tgcss-bg-green-200 tgcss-rounded-full tgcss-px-3 tgcss-pb-1.5 tgcss-pt-1 tgcss-text-green-800" dir="rtl">
                                تایید شده
                            </div>
                            <div v-if="index == 1" class="tgcss-w-auto tgcss-text-xs tgcss-font-semibold tgcss-inline-block tgcss-bg-yellow-100 tgcss-rounded-full tgcss-px-3 tgcss-pb-1.5 tgcss-pt-1 tgcss-text-yellow-600" dir="rtl">
                                در انتظار بررسی
                            </div>
                        </div>
                        <div class="tgcss-p-5">
                            <div class="tgcss-text-xl tgcss-font-semibold">
                                {{ item.CardNumber }}
                            </div>
                            <div class="tgcss-text-sm tgcss-font-semibold tgcss-opacity-70 tracking-wider">
                                {{ item.shaba }}
                            </div> 
                        </div>
                    </div>

                    <div @click="modal = true"  class="tgcss-w-full tgcss-cursor-pointer tgcss-flex  tgcss-justify-center tgcss-items-center tgcss-flex-col tgcss-h-56 tgcss-border-dashed tgcss-border-2 tgcss-border-gray-200  tgcss-rounded-md tgcss-text-left">
                        <div>
                            <svg class="tgcss-m-auto tgcss-w-14 tgcss-opacity-40" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 4.5v15m7.5-7.5h-15" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <div class="tgcss-text-sm tgcss-font-semibold tgcss-opacity-80">
                                افزودن کارت جدید
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>



        <div v-if="modal" class="tgcss-fixed tgcss-inset-0 tgcss-bg-black/20 tgcss-z-50 tgcss-flex tgcss-justify-center tgcss-items-center tgcss-content-center">
            <div class="tgcss-w-96 tgcss-bg-white tgcss-rounded-md">
                <div class="tgcss-p-5">
                    <div class="tgcss-w-full tgcss-flex tgcss-items-center">
                        <div class="tgcss-flex-grow tgcss-text-base tgcss-font-semibold">افزودن کارت جدید</div>
                        <svg @click="modal = false" class="tgcss-w-7 tgcss-p-1 tgcss-bg-gray-100 tgcss-cursor-pointer tgcss-text-gray-700 tgcss-rounded-md" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"></path>
                        </svg>
                    </div>
                </div>
                <div class="tgcss-p-5 tgcss-pt-0">
                    <div class="tgcss-bg-[#fff2e0] tgcss-rounded-md tgcss-p-3 tgcss-flex tgcss-items-start tgcss-mb-6">
                        <svg class="tgcss-w-full tgcss-max-w-7 tgcss-text-[#ff9e15]" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill-rule="evenodd"></path>
                        </svg>
                        <div class="tgcss-flex-grow tgcss-mr-4">
                            <div class="tgcss-leading-7 tgcss-font-normal tgcss-text-sm tgcss-mb-1">
                                شماره کارت باید به‌ نام خودتان باشد.                                   
                            </div>
                        </div>
                    </div>

                    <div class="tgcss-mb-6">
                        <div class="tgcss-flex tgcss-mb-2">
                            <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow">
                                شماره کارت
                            </div>
                        </div>
                        <div class="tgcss-flex tgcss-items-center tgcss-w-full tgcss-rounded-md tgcss-border tgcss-border-gray-200 tgcss-relative">
                            <div class="tgcss-h-12 tgcss-flex tgcss-items-center tgcss-w-full tgcss-relative">
                                <input type="text" class="tgcss-absolute tgcss-w-full tgcss-text-center !tgcss-shadow-none !tgcss-text-base !tgcss-text-gray-700 tgcss-font-semibold !tgcss-bg-transparent !tgcss-h-12 !tgcss-px-3 !tgcss-border-none tgcss-z-20">
                            </div>
                        </div>
                    </div>

                    <div class="tgcss-mb-6">
                        <div class="tgcss-flex tgcss-mb-2">
                            <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow">
                                شماره شبا
                            </div>
                        </div>
                        <div class="tgcss-flex tgcss-items-center tgcss-w-full tgcss-rounded-md tgcss-border tgcss-border-gray-200 tgcss-relative">
                            <div class="tgcss-h-12 tgcss-flex tgcss-items-center tgcss-w-full tgcss-relative">
                                <input type="text" class="tgcss-absolute tgcss-text-center tgcss-w-full !tgcss-shadow-none !tgcss-text-base !tgcss-text-gray-700 tgcss-font-semibold !tgcss-bg-transparent !tgcss-h-12 !tgcss-px-3 !tgcss-border-none tgcss-z-20">
                            </div>
                        </div>
                    </div>

                    <div class="tgcss-w-full tgcss-flex tgcss-font-semibold tgcss-text-base tgcss-mt-5">
                        <button @click="modal = false"  class="tgcss-bg-[#f8931d] tgcss-w-full tgcss-text-white tgcss-font-bold tgcss-pt-2 tgcss-pb-3 tgcss-px-4 !tgcss-rounded-md">
                            افزودن کارت
                        </button>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>


<script>

export default {
    name: 'CryptoWalletBankAccounts',
    components: {},
    data: function () {
        return {
            type:"toman",
            modal:false,
            cardSelect:0,
            bankCards:[
            {
                    'id':1,
                    'name': "سامان",
                    'logo': "https://downloadly.ir/wp-content/uploads/2019/06/%D8%A8%D8%A7%D9%86%DA%A9-%D8%B3%D8%A7%D9%85%D8%A7%D9%86.png",
                    'CardNumber': "6219 8610 2565 1323",
                    "shaba":"IR970560940188802336985001"
                },
                {
                    'id':2,
                    'name': "ملی",
                    'logo': "https://www.rade.ir/wp-content/uploads/2019/12/Melli-logo.fw_.png",
                    'CardNumber': "6037 9911 8653 1770",
                    "shaba":"IR970560940188802336985001"
                }
            ],
        }
    },
    mounted() {
    },
    computed: {
    },
    methods: {
    }
}

</script>
    